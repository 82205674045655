<template>
  <CytomineModal :active="active" @close="$emit('update:active', false)">
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <template v-if="!loading">
      <BSteps
        v-model="activeStep"
        class="flex flex-column h-full w-full align-center justify-center"
      >
        <!-- 1ST STEP -- PROJECT -->
        <BStepItem
          :step="1"
          :label="$t('project')"
          class="flex flex-column justify-center h-full"
          style="width:400px; margin:0 auto;"
        >
          <BField :label="$t('target-project')" class="mb-5">
            <BSelect v-model="toProjectId" expanded @input="onToProjectChanged">
              <option
                v-for="project in projects"
                :key="project.id"
                :value="project.id"
              >
                {{ project.name }}
              </option>
            </BSelect>
          </BField>
        </BStepItem>

        <!-- 2ND STEP -- IMAGES -->
        <BStepItem :step="2" :label="$t('images')" class="px-5">
          <table class="mt-5">
            <thead>
              <tr>
                <td class="pb-3 weight-6">
                  From Image
                </td>
                <td class="pb-3 weight-6">
                  To Image
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(image, i) in selectedImageDetails" :key="i">
                <td class="pt-2 pr-3" style="vertical-align:middle;">
                  {{ image.imageName }}
                </td>
                <td class="pt-2">
                  <BSelect v-model="image.toImageId">
                    <option
                      v-for="toImage in toImages"
                      :key="toImage.id"
                      :value="toImage.id"
                    >
                      {{ toImage.instanceFilename }}
                    </option>
                  </BSelect>
                </td>
              </tr>
            </tbody>
          </table>
        </BStepItem>

        <!-- 3RD STEP -- TERMS -->
        <BStepItem :step="3" label="Terms" class="pt-5">
          <div class="mt-5 pb-3">
            <div class="text-center">
              <BRadio
                v-model="termsSelection"
                class="ml-3"
                name="terms-selection"
                native-value="all"
              >
                All annotations
              </BRadio>

              <BRadio
                v-model="termsSelection"
                class="ml-3"
                name="terms-selection"
                native-value="no-terms"
              >
                Only annotations without terms
              </BRadio>
              <BRadio
                v-model="termsSelection"
                class="ml-3"
                name="terms-selection"
                native-value="terms"
              >
                Annotations with specific terms
              </BRadio>
            </div>
            <table
              v-if="termsSelection === 'terms'"
              class="mt-5"
              style="width:700px; margin:0 auto;"
            >
              <tr>
                <td>
                  <BCheckbox
                    v-model="allTermsSelected"
                    :value="allTermsSelected"
                    :native-value="allTermsSelected"
                    @input="toggleAllTerms"
                  >
                    Toggle All
                  </BCheckbox>
                </td>
                <td />
              </tr>
              <tr v-for="(termGroup, i) in groupedTerms" :key="i">
                <td v-for="term in termGroup" :key="term.id">
                  <BCheckbox
                    v-model="selectedTerms"
                    :value="term.id"
                    :native-value="term.id"
                  >
                    <span
                      class="inline-block p-2"
                      :style="{ background: term.color }"
                    />
                    {{ term.name }}
                  </BCheckbox>
                </td>
              </tr>
            </table>
          </div>
        </BStepItem>

        <!-- 4TH STEP -- ANNOTATIONS -->
        <BStepItem :step="4" label="Annotations" class="pt-4">
          <h2 class="mt-5 pb-5 text-center" style="font-style:italic;">
            {{ $t('annotation-copy-select-user-layers') }}
          </h2>
          <div class="mt-5 pl-5">
            <table style="width:600px; margin:0 auto;">
              <thead>
                <tr>
                  <td class="pb-3" />
                  <td class="pb-3 weight-6">
                    {{ $t('users') }}
                  </td>
                  <td align="center" class="pb-3 weight-6">
                    # {{ $t('annotations') }}
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(userLayer, i) in layersViewModel" :key="i">
                  <td class="pt-1">
                    <BCheckbox
                      v-model="selectedUserIds"
                      :value="userLayer.userId"
                      :native-value="userLayer.userId"
                    />
                  </td>
                  <td class="pt-1">
                    {{ userLayer.fullName }}
                  </td>
                  <td align="center" class="pt-1">
                    {{ userLayer.numAnnotations }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </BStepItem>

        <!-- 5TH STEP -- REVIEW -->
        <BStepItem :step="5" :label="$t('review')" class="step-images pt-4">
          <div class="mt-5 px-5 mx-5">
            <h2 class="mt-4 weight-6 mb-2">
              {{ $t('image-maps') }}
            </h2>
            <table style="width:auto;">
              <tr v-for="image in selectedImageDetails" :key="image.id">
                <td>{{ image.imageName }}</td>
                <td class="px-5">
                  <i class="fas fa-arrow-right" />
                </td>
                <td>{{ getToImage(image.toImageId).instanceFilename }}</td>
              </tr>
            </table>

            <h2 class="mt-4 weight-6 mb-3">
              {{ $t('terms') }}
            </h2>
            <div v-if="selectedTerms.length">
              <div v-for="termName in selectedTerms" :key="termName">
                <span
                  class="inline-block p-2"
                  :style="{ background: getTerm(termName).color }"
                />
                {{ termName }}
              </div>
            </div>
            <div v-else>
              <span>No terms selected (clones all annotations)</span>
            </div>

            <h2 class="mt-4 weight-6 mb-2">
              {{ $t('user-layers') }}
            </h2>
            <table style="width:auto;">
              <tr v-for="userId in selectedUserIds" :key="userId">
                <td>{{ getLayer(userId).fullName }}</td>
                <td class="pl-5">
                  {{ getLayer(userId).numAnnotations }} {{ $t('annotations') }}
                </td>
              </tr>
            </table>
          </div>
        </BStepItem>

        <!-- 6TH STEP -- STATUS -->
        <BStepItem
          :step="6"
          :label="$t('status')"
          class="flex flex-column justify-center h-full step-status text-center"
          style="width:400px; margin:0 auto;"
        >
          <div>
            <BProgress
              type="is-info"
              :value="(imagesProcessed / selectedImageDetails.length) * 100"
              class="mb-4 w-100"
            />
            <h2 class="mt-4 weight-6 mb-2">
              {{ imagesProcessed }} of {{ selectedImageDetails.length }} images
              complete.
            </h2>
            <h2 class="mt-5 mb-2">
              Expected time ~{{
                Math.round((activeImage.numberOfAnnotations / 20000) * 60)
              }}
              seconds for current image
            </h2>
            <h2 class="mt-5 mb-2">
              Please do not leave or reload this page until the process is
              complete.
            </h2>
          </div>
        </BStepItem>

        <!-- NAVIGATION BUTTONS -->
        <template #navigation="{previous, next}">
          <div class="flex w-full pr-5 justify-end">
            <span
              v-if="!isStepValid"
              class="mt-1 mr-4 red"
              style="font-size: 0.8rem; display: inline-block"
            >
              {{ getValidationMessage() }}
            </span>
            <IdxBtn
              class="mr-3"
              :disabled="previous.disabled || activeStep === steps.length - 1"
              @click="previous.action"
            >
              {{ $t('previous') }}
            </IdxBtn>
            <IdxBtn
              v-if="activeStep !== steps.length - 1"
              type="submit"
              color="primary"
              :disabled="!isStepValid"
              @click="goNext(next.action)"
            >
              {{
                activeStep === steps.length - 2
                  ? $t('clone-annotations')
                  : $t('next')
              }}
            </IdxBtn>
          </div>
        </template>
      </BSteps>
    </template>
    <!-- stub to hide footer -->
    <template #footer>
      <span />
    </template>
  </CytomineModal>
</template>
<script>
import { ImageInstance, ProjectCollection } from 'cytomine-client';
import noteApi from '../../services/noteApi.js';
import CytomineModal from '@/components/utils/CytomineModal.vue';
const getDefaultState = () => ({
  allTermsSelected: false,
  activeImage: {},
  title: '',
  tags: [],
  loading: true,
  adaptedParams: [],
  imagesProcessed: 0,
  toProjectId: '',
  projects: [],
  toImages: [],
  selectedTerms: [],
  selectedUserIds: [],
  steps: ['Project', 'Images', 'Terms', 'Annotations', 'Images', 'Status'],
  activeStep: 0,
  selectedImageDetails: [],
  termsSelection: 'all',
  layers: [],
});
export default {
  name: 'BulkAnnotationCopyModal',
  components: {
    CytomineModal,
  },
  props: {
    active: Boolean,
    idProject: {
      type: [String, Number],
      required: true,
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
    revision: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return getDefaultState();
  },
  computed: {
    ontology() {
      return this.$store.state.currentProject.ontology;
    },
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    projectMembers() {
      return this.$store.state.currentProject.members;
    },
    layersViewModel() {
      const layersByUserId = this.layers.reduce((group, layer) => {
        if (group[layer.user] === undefined) {
          group[layer.user] = [];
        }
        group[layer.user].push(layer);
        return group;
      }, {});
      const layerViewModels = [];
      for (const key in layersByUserId) {
        const userLayers = layersByUserId[key];
        const user = this.projectMembers.find(
          (user) => user.id === userLayers[0].user
        );
        layerViewModels.push({
          userId: user?.id || userLayers[0].user,
          fullName: user?.fullName || 'Deleted user',
          numAnnotations: userLayers.reduce(
            (sum, layer) => sum + layer.countAnnotation,
            0
          ),
        });
      }
      return layerViewModels;
    },
    isStepValid() {
      let isValid = false;
      switch (this.activeStep) {
        case 0:
          isValid = this.toProjectId;
          break;
        case 1:
          isValid = this.selectedImageDetails.every((a) => a.toImageId);
          break;
        case 2:
          isValid = true;
          break;
        case 3:
          isValid = this.selectedUserIds.length > 0;
          break;
        case 4:
          isValid = true;
          break;
        case 5:
          isValid = true;
          break;
      }
      return isValid;
    },
    // groups the terms into 2 columns for display purposes
    groupedTerms() {
      const groupSize = 2;
      const groupedTerms = [];
      let activeGroup = [];
      for (const term of this.ontology.terms) {
        activeGroup.push(term);
        if (activeGroup.length === groupSize) {
          groupedTerms.push(activeGroup);
          activeGroup = [];
        }
      }
      if (activeGroup.length > 0) {
        groupedTerms.push(activeGroup);
      }
      return groupedTerms;
    },
  },
  watch: {
    async active() {
      if (this.active) {
        this.loading = true;

        // reset modal state & hide modal
        for (const key in getDefaultState()) {
          this[key] = getDefaultState()[key];
        }

        // get all projects available to user
        const projectRequestProps = this.currentUser.adminByNow
          ? { sort: 'name', order: 'asc' }
          : {
              filterKey: 'user',
              filterValue: this.currentUser.id,
              light: false,
              admin: true,
              sort: 'name',
              order: 'asc',
            };
        const managedProjects = await ProjectCollection.fetchAll(
          projectRequestProps
        );
        if (managedProjects?._data?.length > 0) {
          this.projects = managedProjects._data.filter(
            (a) => a.id !== this.idProject && !a.isClosed
          );
        }

        // get layers on all selected images
        const layersByImage = await Promise.all(
          this.selectedImages.map(async (image) => {
            const imageInstance = await ImageInstance.fetch(image.id);
            return await imageInstance.fetchAnnotationsIndex();
          })
        );
        if (layersByImage?.length > 0) {
          this.layers = layersByImage.flatMap((a) => a);
        }
        this.loading = false;
      }
    },
  },
  methods: {
    goNext(nextStep) {
      if (this.activeStep === this.steps.length - 2) {
        nextStep();
        this.cloneAnnotations();
      } else {
        nextStep();
      }
    },
    async onToProjectChanged() {
      this.loading = true;

      // ensure ontology of 'toProject' is not locked
      const selectedProject = this.projects.find(
        (a) => a.id === this.toProjectId
      );

      if (!selectedProject.ontology) {
        this.$notify({
          type: 'error',
          text: this.$t('target-project-has-no-ontology'),
        });
        setTimeout(() => (this.toProjectId = ''), 200);
        this.loading = false;
        return;
      }

      const targetOntology = await noteApi.get(
        `/napi/ontology/${selectedProject.ontology}?includeDeletedTerms=false`
      );

      if (targetOntology.isLocked) {
        this.$notify({
          type: 'error',
          text: this.$t('target-project-ontology-locked'),
        });
        this.toProjectId = '';
        this.loading = false;
        return;
      }

      // get all images in 'toProject'
      const imageResults = await noteApi.get(
        `/api/project/${this.toProjectId}/imageinstance.json`
      );
      this.toImages = imageResults.collection;

      // set default 'toImage' if similar named image exists in 'toProject'
      this.selectedImageDetails = this.selectedImages.map((image) => ({
        imageId: image.id,
        imageName: image.instanceFilename,
        imagePath: image.fullPath,
        toImageId:
          this.toImages.find(
            (toImage) => toImage.instanceFilename === image.instanceFilename
          )?.id || '',
      }));

      this.loading = false;
    },
    getToImage(id) {
      return this.toImages.find((a) => a.id === id) || {};
    },
    getLayer(userId) {
      return this.layersViewModel.find((layer) => layer.userId === userId);
    },
    getTerm(termId) {
      return this.ontology.terms.find((term) => term.id === termId);
    },
    toggleAllTerms() {
      if (!this.allTermsSelected) {
        this.selectedTerms = [];
      } else {
        this.selectedTerms = this.ontology.terms.map((term) => term.id);
      }
    },
    deselectAllTerms() {
      this.selectedTerms = [];
    },
    getValidationMessage() {
      switch (this.activeStep) {
        case 0:
          return this.$t('must-select-project');
        case 1:
          return this.$t('all-images-must-be-mapped');
        case 2:
          return this.$t('term-validation-message');
        case 3:
          return this.$t('must-select-layer');
      }
    },

    async cloneAnnotations() {
      try {
        for (const image of this.selectedImageDetails) {
          const requestBody = {
            toImage: image.toImageId,
            toProject: this.toProjectId,
            fromProject: parseInt(this.idProject),
            fromImage: image.imageId,
            users: [...this.selectedUserIds],
            annotationsWithoutTerms: this.termsSelection === 'no-terms',
          };

          if (this.selectedTerms.length) {
            requestBody.terms = [...this.selectedTerms];
          }

          this.activeImage = this.selectedImages.find(
            (a) => a.id === image.imageId
          );
          const response = await noteApi.post(`/napi/annotation/clone`, {
            data: requestBody,
          });

          if (response.success) {
            this.imagesProcessed++;
          }
        }

        this.$notify({
          type:
            this.imagesProcessed === this.selectedImageDetails.length
              ? 'success'
              : 'error',
          text: this.$t('annotations-copied', {
            success: this.imagesProcessed,
            total: this.selectedImageDetails.length,
          }),
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          text: error?.message ?? this.$t('error-cloning-annotations'),
        });
      } finally {
        // reset modal state & hide modal
        for (const key in getDefaultState()) {
          this[key] = getDefaultState()[key];
        }
        this.$emit('update:active', false);
      }
    },
  },
};
</script>
<style scoped>
h4 {
  font-weight: bold;
}
>>> .animation-content {
  max-width: 60% !important;
  width: 60%;
}
>>> .modal-card {
  width: 100%;
  height: 80vh;
}
.image-overview {
  max-height: 4rem;
  max-width: 10rem;
}
.red {
  color: red;
}
</style>
<style>
.b-steps .step-content {
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: auto !important;
}
.b-steps .steps {
  width: 100%;
}
.b-steps .step-item {
  outline: none;
  box-shadow: none;
}
.b-steps .step-content > div {
  outline: none;
  box-shadow: none;
}
.step-images .b-radio.radio .control-label {
  padding-left: 0 !important;
}
label .label {
  margin-bottom: 0px;
}
label .label:not(:last-child) {
  margin-bottom: 0px;
}
</style>
