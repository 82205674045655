<template>
  <div>
    <table class="table">
      <tbody>
        <tr v-if="isPropDisplayed('overview')">
          <td class="prop-label weight-6">
            {{ $t('overview') }}
          </td>
          <td>
            <RouterLink :to="`/project/${image.project}/image/${image.id}`">
              <img
                :src="image.thumb"
                :alt="image.originalFilename"
                class="max-w-400 max-h-240"
              />
            </RouterLink>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('status')">
          <td class="prop-label weight-6">
            {{ $t('status') }}
          </td>
          <td>
            <ImageStatus :image="image" />
          </td>
        </tr>
        <tr v-if="isPropDisplayed('numberOfAnnotations')">
          <td class="prop-label weight-6">
            {{ $t('user-annotations') }}
          </td>
          <td>
            <RouterLink
              :to="
                `/project/${image.project}/annotations?image=${image.id}&type=user`
              "
            >
              {{ image.numberOfAnnotations }}
            </RouterLink>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('numberOfJobAnnotations')">
          <td class="prop-label weight-6">
            {{ $t('analysis-annotations') }}
          </td>
          <td>
            <RouterLink
              :to="
                `/project/${image.project}/annotations?image=${image.id}&type=algo`
              "
            >
              {{ image.numberOfJobAnnotations }}
            </RouterLink>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('numberOfReviewedAnnotations')">
          <td class="prop-label weight-6">
            {{ $t('reviewed-annotations') }}
          </td>
          <td>
            <RouterLink
              :to="
                `/project/${image.project}/annotations?image=${image.id}&type=reviewed`
              "
            >
              {{ image.numberOfReviewedAnnotations }}
            </RouterLink>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('description')">
          <td class="prop-label weight-6">
            {{ $t('description') }}
          </td>
          <td>
            <IdxDescription :object="image" :prevent-edit="!canEdit" />
          </td>
        </tr>
        <!-- Tags hidden until scoped access per user per project -->
        <!-- <tr v-if="isPropDisplayed('tags')">
          <td class="prop-label weight-6">
            {{ $t('tags') }}
          </td>
          <td>
            <CytomineTags :object="image" :can-edit="canEdit" />
          </td>
        </tr> -->
        <tr
          v-if="
            isPropDisplayed('properties') &&
              isFieldDisplayed('image-properties')
          "
        >
          <td class="prop-label weight-6">
            {{ $t('properties') }}
          </td>
          <td>
            <CytomineProperties
              :object="image"
              :show-default-qc="true"
              :hidden-props="[
                'Tile Count',
                'Average Focus',
                'Percent In-Focus',
              ]"
              :can-edit="canEdit && isRepresentative && isSuperAdmin"
            />
          </td>
        </tr>
        <tr v-if="isPropDisplayed('attachedFiles')">
          <td class="prop-label weight-6">
            {{ $t('attached-files') }}
          </td>
          <td>
            <AttachedFiles :object="image" :can-edit="canEdit" />
          </td>
        </tr>
        <tr
          v-if="
            isPropDisplayed('slideLabel') &&
              isFieldDisplayed('image-slide-label')
          "
        >
          <td class="prop-label weight-6">
            {{ $t('slide-label') }}
          </td>
          <td>
            <a v-if="isLabelValid" :href="imageLabel" target="_blank">
              <img
                :src="imageLabel"
                :alt="
                  blindMode
                    ? `${image.blindedName} label`
                    : `${image.originalFilename} label`
                "
                class="max-w-160 max-h-240 color-blue"
                @error="isLabelValid = false"
              />
            </a>
            <em v-else>
              {{ $t('slide-label-not-available') }}
            </em>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('slidePreview')">
          <td class="prop-label weight-6">
            {{ $t('slide-preview') }}
          </td>
          <td>
            <IdxBtn
              v-if="image.macroURL"
              plain
              @click="isMetadataModalActive = true"
            >
              <img
                :src="image.macroURL"
                :alt="blindMode ? image.blindedName : image.originalFilename"
                class="max-w-400 max-h-240 color-blue"
              />
            </IdxBtn>
            <em v-else>
              {{ $t('slide-preview-not-available') }}
            </em>
          </td>
        </tr>
        <tr
          v-if="
            isFieldDisplayed('image-slide-label') &&
              isPropDisplayed('originalFilename') &&
              (!blindMode || canManageProject)
          "
        >
          <td class="prop-label weight-6">
            {{ $t('originalFilename') }}
          </td>
          <td>
            {{ image.originalFilename }}
          </td>
        </tr>
        <tr v-if="isPropDisplayed('format')">
          <td class="prop-label weight-6">
            {{ $t('format') }}
          </td>
          <td class="w-full uppercase">
            {{ image.extension }}
          </td>
        </tr>
        <tr v-if="isPropDisplayed('vendor')">
          <td class="prop-label weight-6">
            {{ $t('vendor') }}
          </td>
          <td>
            <img
              v-if="vendor"
              :src="vendor.imgPath"
              :alt="vendor.name"
              :title="vendor.name"
              width="140"
              height="64"
            />
            <template v-else>
              {{ $t('unknown') }}
            </template>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('size')">
          <td class="prop-label weight-6">
            {{ $t('image-size') }}
          </td>
          <td>
            {{ `${image.width} x ${image.height} ${$t('pixels')}` }}
          </td>
        </tr>
        <tr v-if="isPropDisplayed('resolution')">
          <td class="prop-label weight-6">
            {{ $t('resolution') }}
          </td>
          <td>
            <template v-if="image.resolution">
              {{ image.resolution.toFixed(6) }}
              {{ $t('um-per-pixel') }}
            </template>
            <template v-else>
              {{ $t('unknown') }}
            </template>
          </td>
        </tr>
        <tr v-if="isPropDisplayed('magnification')">
          <td class="prop-label weight-6">
            {{ $t('magnification') }}
          </td>
          <td>
            <template v-if="image.magnification">
              {{ image.magnification }}
            </template>
            <template v-else>
              {{ $t('unknown') }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="prop-label weight-6">
            {{ $t('actions') }}
          </td>
          <td>
            <div class="flex gap-8 flex-wrap">
              <IdxBtn small @click="isMetadataModalActive = true">
                {{ $t('button-metadata') }}
              </IdxBtn>
              <template v-if="canEdit">
                <IdxBtn
                  v-if="!image.reviewed && !image.inReview"
                  :to="
                    `/project/${image.project}/image/${image.id}?action=review`
                  "
                  small
                >
                  {{ $t('button-start-review') }}
                </IdxBtn>

                <template v-else-if="image.reviewUser === currentUser.id">
                  <IdxBtn v-if="image.reviewed" small @click="cancelReview">
                    {{ $t('button-unvalidate-review') }}
                  </IdxBtn>
                  <template v-else>
                    <IdxBtn
                      :to="
                        `/project/${image.project}/image/${image.id}?action=review`
                      "
                      small
                    >
                      {{ $t('button-continue-review') }}
                    </IdxBtn>
                    <IdxBtn small @click="cancelReview">
                      {{ $t('button-cancel-review') }}
                    </IdxBtn>
                  </template>
                </template>

                <IdxBtn
                  v-if="!blindMode || canManageProject"
                  small
                  @click="isRenameModalActive = true"
                >
                  {{ $t('button-rename') }}
                </IdxBtn>
                <IdxBtn small @click="isCalibrationModalActive = true">
                  {{ $t('button-set-calibration') }}
                </IdxBtn>
                <IdxBtn small @click="isMagnificationModalActive = true">
                  {{ $t('button-set-magnification') }}
                </IdxBtn>
              </template>
              <IdxBtn
                v-if="canDownloadImages || canManageProject"
                small
                @click="downloadImage"
              >
                {{ $t('download') }}
              </IdxBtn>
              <template v-if="currentUser.admin">
                <IdxBtn small @click="showCloneAnnotationsModal = true">
                  {{ $t('clone-annotations') }}
                </IdxBtn>
                <IdxBtn
                  v-if="!currentProject.isClosed"
                  color="danger"
                  small
                  @click="confirmDeleteAnnotations"
                >
                  {{ $t('annotations-delete') }}
                </IdxBtn>
              </template>
              <IdxBtn
                v-if="canEdit && !currentProject.lockImageSet"
                color="danger"
                small
                @click="confirmDeletion"
              >
                {{ $t('delete') }}
              </IdxBtn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <RenameModal
      :title="$t('rename-image')"
      :current-name="image.instanceFilename"
      :active.sync="isRenameModalActive"
      @rename="rename"
    />

    <MagnificationModal
      :image="image"
      :active.sync="isMagnificationModalActive"
      @setMagnification="(event) => $emit('setMagnification', event)"
    />

    <CalibrationModal
      :image="image"
      :active.sync="isCalibrationModalActive"
      @setResolution="(event) => $emit('setResolution', event)"
    />

    <ImageMetadataModal
      :active.sync="isMetadataModalActive"
      :image="image"
      :blind-mode="blindMode"
    />

    <BulkAnnotationCopyModal
      :active.sync="showCloneAnnotationsModal"
      :selected-images="[image]"
      :id-project="image.project"
    />
  </div>
</template>

<script>
import MagnificationModal from './MagnificationModal.vue';
import CalibrationModal from './CalibrationModal.vue';
import ImageMetadataModal from './ImageMetadataModal.vue';
import ImageStatus from './ImageStatus.vue';
import BulkAnnotationCopyModal from './BulkAnnotationCopyModal.vue';
import constants from '@/utils/constants.js';
import noteApi, { downloadImages } from '@/services/noteApi.js';
import vendorFromMime from '@/utils/vendor.js';
import IdxDescription from '@/components/description/IdxDescription.vue';
import CytomineProperties from '@/components/property/CytomineProperties.vue';
import CytomineTags from '@/components/tag/CytomineTags.vue';
import AttachedFiles from '@/components/attached-file/AttachedFiles.vue';
import RenameModal from '@/components/utils/RenameModal.vue';

const apiRoot = process.env.VUE_APP_CYTOMINE_CORE_HOST;

export default {
  name: 'ImageDetails',
  components: {
    IdxDescription,
    CytomineTags,
    CytomineProperties,
    AttachedFiles,
    MagnificationModal,
    CalibrationModal,
    ImageMetadataModal,
    ImageStatus,
    RenameModal,
    BulkAnnotationCopyModal,
  },
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    image: { type: Object, required: true },
    excludedProperties: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRenameModalActive: false,
      isCalibrationModalActive: false,
      isMagnificationModalActive: false,
      isMetadataModalActive: false,
      imageLabel: '',
      isLabelValid: true,
      showCloneAnnotationsModal: false,
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {object} */
    currentProject() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {boolean} */
    blindMode() {
      return (
        (this.$store.state.currentProject.project || {}).blindMode || false
      );
    },
    /** @returns {boolean} */
    canDownloadImages() {
      return (
        (this.$store.state.currentProject.project || {})
          .areImagesDownloadable || false
      );
    },
    cytomineHostUrl: () => constants.CYTOMINE_CORE_HOST,
    /** @returns {boolean} */
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {boolean} */
    canEdit() {
      return (
        this.editable &&
        this.$store.getters['currentProject/canEditImage'](this.image)
      );
    },
    isSuperAdmin() {
      return this.currentUser.adminByNow;
    },
    isRepresentative() {
      return this.$store.getters['currentProject/isRepresentative'];
    },
    /** @returns {boolean} */
    /** @returns {string} */
    imageNameNotif() {
      return this.blindMode
        ? this.image.blindedName
        : this.image.instanceFilename;
    },
    /** @returns {object} */
    vendor() {
      return vendorFromMime(this.image.mime);
    },
  },
  created() {
    this.imageLabel = `${apiRoot}/api/abstractimage/${this.image.baseImage}/associated/label.png?`;
  },
  methods: {
    isFieldDisplayed(field) {
      return this.currentUser.admin || this.configUI[`project-${field}-field`];
    },
    isPropDisplayed(prop) {
      return this.currentUser.admin || !this.excludedProperties.includes(prop);
    },
    async cancelReview() {
      const image = this.image;
      const errorLabel = image.reviewed
        ? 'notif-error-unvalidate-review'
        : 'notif-error-cancel-review';
      try {
        await noteApi.delete(
          `/api/imageinstance/${image.id}/review.json?cancel=true`
        );
        this.$emit('review-canceled', image);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t(errorLabel),
        });
      }
    },

    async rename(newName) {
      const oldName = this.image.instanceFilename;
      try {
        // eslint-disable-next-line vue/no-mutating-props
        this.image.instanceFilename = newName;
        await noteApi.put(`/api/imageinstance/${this.image.id}.json`, {
          json: this.image,
        });
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-image-rename', {
            imageName: this.image.instanceFilename,
          }),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-image-rename', { imageName: oldName }),
        });
      }
      this.isRenameModalActive = false;
    },

    confirmDeletion() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete-image'),
        message: this.$t('delete-image-confirmation-message', {
          imageName: this.imageNameNotif,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: this.deleteImage,
      });
    },
    async deleteImage() {
      try {
        await noteApi.delete(`/api/imageinstance/${this.image.id}.json`);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-image-deletion', {
            imageName: this.imageNameNotif,
          }),
        });
        this.$emit('delete');

        const updatedProject = this.$store.state.currentProject.project.clone();
        updatedProject.numberOfImages--;
        this.$store.dispatch('currentProject/updateProject', updatedProject);
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-image-deletion', {
            imageName: this.imageNameNotif,
          }),
        });
      }
    },
    downloadImage() {
      downloadImages(this.image.project, [this.image.id])
        .then(() => {
          this.$notify({
            text: this.$t('download-links-sent'),
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            text: this.$t('unexpected-error-info-message'),
          });
        });
    },
    confirmDeleteAnnotations() {
      this.$buefy.dialog.confirm({
        title: this.$t('annotations-delete'),
        message: this.$t('annotations-delete-confirmation', {
          imageName: this.imageNameNotif,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: this.deleteAnnotations,
      });
    },
    async deleteAnnotations() {
      try {
        noteApi.delete('/napi/annotation', {
          data: {
            projectId: this.image.project,
            imageId: this.image.id,
            hardDelete: true,
          },
        });
        this.$notify({
          type: 'success',
          duration: -1,
          text: this.$t('delete-annotations-success'),
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          type: 'error',
          text: this.$t('unexpected-error-info-message'),
        });
      }
    },
  },
};
</script>

<style scoped>
td.prop-label {
  white-space: nowrap;
}
</style>
