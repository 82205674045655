<template>
  <div class="project-actions-wrapper">
    <BLoading :is-full-page="true" :active.sync="isLoading" />

    <RenameModal
      :title="$t('project-rename')"
      :current-name="project.name"
      :active.sync="isRenameModalActive"
      @rename="rename"
    />

    <div class="buttons">
      <template v-if="canEditProject && currentUser.admin">
        <button
          :class="size"
          class="button"
          @click="isRenameModalActive = true"
        >
          {{ $t('button-rename') }}
        </button>
        <button
          v-if="project.lockImageSet"
          :class="size"
          class="button is-danger"
          @click="toggleLockImageSet(false)"
        >
          {{ $t('unlock-image-set') }}
        </button>
        <button
          v-else
          v-tooltip="$t('lock-image-set-description')"
          :class="size"
          class="button is-danger"
          @click="toggleLockImageSet(true)"
        >
          {{ $t('lock-image-set') }}
        </button>
        <button
          :class="size"
          class="button is-danger"
          @click="confirmLockProject()"
        >
          {{ $t('lock-project') }}
        </button>
        <button :class="size" class="button is-danger" @click="deleteProject()">
          {{ $t('delete') }}
        </button>
      </template>
      <template v-if="currentProject.isClosed && currentUser.admin">
        <span class="italic"
          >{{ $t('project-unlock-message') }}
          <a
            target="_blank"
            href="https://revealbio.atlassian.net/servicedesk/customer/portal/4/group/-1"
            >{{ $t('support-request') }}</a
          >.</span
        >
      </template>
    </div>
  </div>
</template>

<script>
import noteApi from '@/services/noteApi';
import CytomineModal from '@/components/utils/CytomineModal';
import RenameModal from '@/components/utils/RenameModal';

export default {
  name: 'ProjectActions',
  components: {
    CytomineModal,
    RenameModal,
  },
  props: {
    project: { type: Object },
    size: {
      type: String,
      default: 'is-small',
    },
  },
  data() {
    return {
      isRenameModalActive: false,
      isLoading: false,
    };
  },
  computed: {
    canEditProject() {
      return this.$store.getters['currentProject/canEditProject'];
    },
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    currentProject() {
      return this.$store.state.currentProject.project;
    },
  },
  methods: {
    async rename(newName) {
      const updatedProject = this.project.clone();
      try {
        updatedProject.name = newName;
        await updatedProject.save();
        this.$emit('update', updatedProject);
        this.$notify({
          type: 'success',
          text: this.$t('project-rename-notif-success', {
            projectName: updatedProject.name,
          }),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-rename-notif-error', {
            projectName: this.project.name,
          }),
        });
      }
      this.isRenameModalActive = false;
    },

    deleteProject() {
      this.$buefy.dialog.confirm({
        title: this.$t('project-delete'),
        message: this.$t('project-delete-confirmation-message', {
          projectName: this.project.name,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.$emit('delete'),
      });
    },

    confirmLockProject() {
      this.$buefy.dialog.confirm({
        title: this.$t('warning-caps'),
        message: this.$t('lock-project-description'),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.lockProject(),
      });
    },

    async lockProject() {
      try {
        this.isLoading = true;
        const project = this.project.clone();
        const newOntologyId = await noteApi.post(
          `/napi/project/${this.project.id}/lock`
        );

        project.ontology = newOntologyId;
        project.isClosed = true;

        await project.save();

        this.$emit('update', project);
        this.$notify({
          type: 'success',
          text: this.$t('project-lock-notif-success'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-lock-notif-error'),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async toggleLockImageSet(value) {
      const updatedProject = this.project.clone();
      try {
        updatedProject.lockImageSet = value;
        await updatedProject.save();
        this.$emit('update', updatedProject);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-project-updated'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-project-updated'),
        });
      }
    },
  },
};
</script>
