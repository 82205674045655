<template>
  <span v-if="image">
    <template v-if="image.blindedName">
      <span class="blind-indication">[{{ $t('blinded-name-indication') }}]</span>
      {{ getTruncatedString(image.blindedName) }}
      <template v-if="image.instanceFilename && showBothNames">
        <br />
        <span class="true-name">
          {{ getTruncatedString(image.instanceFilename) }}
          <!-- backend will return this prop iff user is allowed to view it (admin or project manager) -->
        </span>
      </template>
    </template>
    <template v-else>{{ getTruncatedString(image.instanceFilename) }}</template>
  </span>
</template>

<script>
export default {
  name: 'ImageName',
  props: {
    image: Object,
    showBothNames: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getTruncatedString(str) {
      if (this.maxLength && str.length > this.maxLength) {
        return str.substring(0, this.maxLength) + '...';
      }
      return str;
    },
  },
};
</script>

<style scoped>
.blind-indication {
  font-size: 0.9em;
  text-transform: uppercase;
}

.true-name {
  margin-top: 0.2em;
  font-size: 0.9em;
  color: #888;
}
</style>
